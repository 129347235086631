import { Box, Button, Divider, Typography } from '@mui/material'
import { useEffect } from 'react';
import { Zoom, cssTransition, toast } from 'react-toastify';
import {Navbar} from '../../Navbar/Navbar';
import React from 'react';
import './Home.css'
import { BottomNav } from '../../BottomNav/BottomNav';
import { ThemeColors } from '../../themes/mui';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

export const Toast_UpdateApp = (registration) => (
    <Box display={'flex'} justifyContent={'space-between'}>
    <Typography sx={{width: '70%',
        //marginTop:'-10px',
        //paddingTop:0,
        display: {xs: 'flex', md: 'flex'},
        fontFamily: 'monospace',
        fontWeight: 700,
        fontSize: '12px',
        //letterSpacing: '.3rem',
        color: ThemeColors.secondary,
        textDecoration: 'none',
    }} >{i18n.t('registerworker.new_version')}</Typography>
    <Divider sx={{color:'GrayText'}} orientation='vertical'/>
    <Button sx={{marginLeft:"10px", fontSize:'12px'}} fullWidth={true} size='small' variant='contained' 
        onClick={()=>{registration.waiting.postMessage({ type: 'SKIP_WAITING' });;window.location.reload()}}>Update</Button>
    </Box>
  );
/*const Trans = cssTransition({
    enter: 'zoomIn',
    appendPosition: false,
    collapse: false,
  });*/

const Home = (props:any) => {
    const navBarId = React.useRef<any>(null);
    const footerId = React.useRef<any>(null);
    const { t } = useTranslation();

    useEffect(() => {
        console.log(`[Home] :: Home page return`)
        navBarId.current = toast(<Navbar home={props.home} version={props.version} />, {position:'top-center',draggable:false, autoClose: false, closeButton:false, transition:Zoom, style:{boxShadow:'none', backgroundColor:ThemeColors.primary} });
        footerId.current = toast(<BottomNav />, {position:'bottom-center', draggable:false, autoClose: false, closeButton:false, transition:Zoom, style:{height:'40px', boxShadow:'none', backgroundColor:ThemeColors.primary} });
        toast(t("welcome"), {type:'info'})
    }, [])

    return (
        <Box className="PageContainer">

        </Box>
    )
}

export default Home
