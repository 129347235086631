import React, { useEffect } from 'react';
import theme from './themes/mui'
import './App.css';
import { CssBaseline, ThemeProvider } from '@mui/material';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Home, { Toast_UpdateApp } from './views/Home/Home';
import { buildInfo } from './buildInfo';
import { useNotificationCenter } from "react-toastify/addons/use-notification-center"
import i18n from 'i18next';

const configuration: serviceWorkerRegistration.Config = {
  onUpdate: (registration) => {
    if (registration && registration.waiting) {
      //registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      toast.info(()=>Toast_UpdateApp(registration), { autoClose: false});
      /*if (window.confirm(i18n.t('registerworker.new_version'))) {
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        window.location.reload();
      }*/
    }
  },
  onSuccess:(registration) => {
    if (registration) {
      
      if (window.confirm(i18n.t('registerworker.updated'))) {
        
      }
    }
  }
};

function App() {
  serviceWorkerRegistration.register(configuration);
  const {
    notifications,
    clear,
    markAllAsRead,
    markAsRead,
    add,
    update,
    remove,
    find,
    sort,
    unreadCount
  } = useNotificationCenter()
  useEffect(() => {
    const buildDate = new Date();
    console.log(`Build Number: ${buildInfo.buildVersion}`);
    console.log(`Build Date: ${buildDate.toString()}`);

  }, [])
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="AppContainer">
          {/*<Navbar home={true} version={buildInfo.buildVersion}/>*/}
          <Home home={true} version={buildInfo.buildVersion} />
          {/*<BottomNav />*/}
          <ToastContainer position='top-center' toastStyle={{ boxShadow: 'none' }} />
        </div>
      </ThemeProvider>
    </div>
  );
}

export default App;
