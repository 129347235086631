import { useState } from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'



import styles from './Navbar.module.css'
import { HomeMiniTwoTone, HomeTwoTone } from '@mui/icons-material'
import { ThemeColors } from '../themes/mui'

export interface NavBarProps {
    version?: string
    home?: boolean
}

export const Navbar = (props: NavBarProps) => (<Box sx={{ display: { xs: 'flex', md: 'flex' }, }} flexDirection={'column'}>
    <Typography sx={{
        mr: 2,
        //display: {xs: 'none', md: 'flex'},
        fontFamily: 'monospace',
        fontWeight: 700,
        fontSize: '8px',
        //letterSpacing: '.3rem',
        color: ThemeColors.secondary,
        textDecoration: 'none',
    }} >v{props.version}</Typography>
    <HomeTwoTone color={props.home ? 'success' : 'error'} />
</Box>)

/*
const Navbar = (props: NavBarProps) => {

    return (
        <AppBar position="static" color="primary" className={styles.Navbar}>
            <Container maxWidth="xl">
                <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
                    <Box
                        className={styles.FlexCentered}
                    >
                        <Box sx={{ display: { xs: 'flex', md: 'flex' }, }} flexDirection={'column'}>
                            <Typography sx={{
                                mr: 2,
                                //display: {xs: 'none', md: 'flex'},
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                fontSize: '8px',
                                //letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }} >v{props.version}</Typography>
                            <HomeTwoTone color={props.home ? 'success' : 'error'} />
                        </Box>
                        <Typography
                            variant="h6"
                            noWrap
                            sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            WEBO HOMEHUB
                        </Typography>
                    </Box>
                    <Typography
                        variant="h5"
                        noWrap
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        WEBO HOMEHUB
                    </Typography>
                </Toolbar>
            </Container>
        </AppBar>
    )
}*/


